import React, { useState } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Layout from '../components/layout'
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal';

const Portfolio = () => {

  const PortfolioSingle = props => {

    const { data } = props
    const { node } = data
    const { company, logo, technologies, thumbnail, fullscreen, link, color } = node

    const [show, setShow] = useState(false)

    const toggleModal = () => {
      setShow(!show)
    }

    const TagList = props => {
      const { items } = props
      return (
        <ul className="tag-list">
          {
            items.map((item, index) => {
              return(
              <li key={index} className="tag">{item}</li>
              )
            })
          }
        </ul>
      )
    }


    return (
      <React.Fragment>
        <Row>
          <Col md="9" style={{ padding: '0' }}>
            <img
              className="d-block w-100"
              src={thumbnail.file.url + '?w=1000'}
              alt={company} />
          </Col>
          <Col md="3" className="my-auto" style={{ textAlign: 'center', padding: '0' }}>
            <img className="company-logo" src={logo.file.url} />
            <Button size="lg" onClick={toggleModal}>View More</Button>
            <TagList items={technologies} />
          </Col>
        </Row>
        <Modal show={show}>
          <Modal.Body className="portfolio-modal" style={{ backgroundColor: color }}>
            <Row>
              <Col md="9" className="portfolio-modal-left">
                <img className="fullpage-img" src={fullscreen.file.url + '?w=2334'} />
              </Col>
              <Col md="3" className="portfolio-modal-right my-auto">
                <img className="company-logo" src={logo.file.url} />
                <TagList items={technologies} />
                <a style={{ color: 'white' }} href={link} target="_blank ">VISIT SITE</a>
                <Button onClick={toggleModal}>Close</Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }

  const PortfolioItems = props => {
    const { data } = props

    return (
      <React.Fragment>
        <Carousel indicators={false}>
          {data && data.map(edge => {
            return (
              <Carousel.Item style={{ backgroundColor: edge.node.color }}>
                <PortfolioSingle data={edge} />
              </Carousel.Item>
            )
          })
          }
        </Carousel>
      </React.Fragment>
    )
  }


  return (
    <Layout>
      <Container>
        <Row className="space">
          <Col>
            <h2>Our Work</h2>
            <p>Our process gets to the heart of how to properly present your ideas on the web. Take a look at some of our favorite projects below.</p>
          </Col>
        </Row>
      </Container>
      <div class="portfolio-item-list">
        <StaticQuery
          query={graphql`
        query PortfolioQuery {
          allContentfulPortfolioItem {
            edges {
              node {
                thumbnail {
                  file {
                    url
                  }
                }
                color
                company
                link
                id
                logo {
                  file {
                    url
                  }
                }
                fullscreen {
                  file {
                    url
                  }
                }
                technologies
              }
            }
          }
        }
      `}
          render={(data) => {
            return (
              <PortfolioItems data={data.allContentfulPortfolioItem.edges} />
            )
          }
          }
        />
      </div>
    </Layout>
  )

}

export default Portfolio
